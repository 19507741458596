import { Box } from '../box';
import { Grid } from '../grid';

import type { PropsWithChildren } from 'react';

export const Wrapper = ({ children }: PropsWithChildren) => (
  <Grid>
    <Box as="section" gridColumn={{ sm: '1 / span 12' }}>
      {children}
    </Box>
  </Grid>
);

export const PageWrapper = ({ children }: PropsWithChildren) => (
  <Grid>
    <Box as="section" marginTop={{ lg: '5' }} gridColumn={{ sm: '1 / span 12', lg: '3 / span 8' }}>
      {children}
    </Box>
  </Grid>
);
